<script setup lang="ts">
import ZoomVideo from "@zoom/videosdk";
import {useDeviceStore} from "~/store/deviceStore";
import {captureException} from "@sentry/vue";

const deviceStore = useDeviceStore()

onMounted(() => {
  if(ZoomVideo.checkSystemRequirements().video && ZoomVideo.checkSystemRequirements().audio) {
    console.log('System requirements are met')
    deviceStore.setSystemRequirements(true)
  } else {
    console.error('System requirements are not met')
    deviceStore.setSystemRequirements(false)
    captureException(new Error('System requirements are not met'))
  }
})
</script>

<template>

</template>