<script setup lang="ts">
const route = useRoute()
const config = useRuntimeConfig()

const loadScripts = () => {
  const record = route.query.record !== '0'

  if (record && config.public.environment === 'production') {
    const hotjarScript = document.createElement('script');
    hotjarScript.innerHTML = `(function(h,o,t,j,a,r){
        h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
        h._hjSettings={hjid:5013158,hjsv:6};
        a=o.getElementsByTagName('head')[0];
        r=o.createElement('script');r.async=1;
        r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
        a.appendChild(r);
    })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');`;
    document.head.appendChild(hotjarScript);
  } else {
    console.log('No recording')
  }
}

onBeforeMount(() => {
  loadScripts()
})

</script>

<template>
  <div>
    <slot/>
    <client-only>
      <dialog-requirements/>
    </client-only>
  </div>
</template>